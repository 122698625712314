.main-header{
    background-color: $white-color;
    box-shadow: $shadow-1;
    padding: 16px 10px;
    &--inner{
        @include common-flex-jsb;
        .powered-by{
            font-size: 14px;
            color: $black-color;
            font-weight: 500;
            line-height: 1;
            display: flex;
            align-items: center;
            max-width: 60%;
            text-align: right;
            svg{
                margin-left: 4px;
                margin-top: -1px;
            }
        }
    }

}
