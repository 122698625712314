.data-wrapper{
    @include position-relative;
    min-height: calc(100vh - 88px - 60px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px 10px;
}
.global-card{
    @include position-relative;
    @include common-flex;
    background-color: $white-color;
    border-radius: 10px;
    padding: 24px;
    @include sm-mobile{
        padding: 12px 20px;

    }
    &--intro{
        justify-content: center;
        flex-direction: column;
        padding: 31px 31px 57px 31px;
        p{
            margin-bottom: 30px;
        }
        h2{
            text-align: center;
        }
        h5{
          font-size: 20px;
          margin-top: 22px;
        }
    }
    &__inner-wrapper{
        width: 100%;
        .form-control__textarea{
            font-size: 15px;
            color: $secondary-color;
            padding: 14px 15px;
            width: 100%;
            height: 127px;
            border-radius: 6px;
            border: 1px solid $light-gray-10;
            @media(max-width:565.98px){
              height: 80px;
            }
            &:focus-visible{
                outline-color: $primary-color;
            }
        }
        ::placeholder{
            opacity: .5;
        }
        h3{
            font-size: 22px;
            font-weight: 500;
            color: $secondary-color;
            margin-bottom: 5px;
            margin-top: 32px;
        }
        p{
            font-size: 16px;
            color: $secondary-color;
            line-height: 1.5;
            margin-bottom: 20px;
            @media( max-width:767.98px){
                line-height: 1.3;
                font-size: 15px;
                margin-bottom: 15px;
            }
        }
        small{
            display: block;
            font-size: 15px;
            padding-bottom: 5px;
            color: #69768e;
        }
        .upload-image{
            @include common-flex;
            flex-wrap: wrap;
            width: 100%;
            gap: 5px;
            .image-uploaded{
                width: 60px;
                height: 60px;
                border-radius: 15px;
                position: relative;
                background-color: $light-gray-60;
                overflow: hidden;
                @include common-flex-jc;
                img{
                    border-radius: 10px;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                .delete-img{
                    position: absolute;
                    top: 4px;
                    right: 3px;
                    width: 16px;
                    height: 16px;
                    padding: 0;
                    background: none;
                    border: none;
                    line-height: 0;
                    min-height: unset;
                }
            }
            &__input{
                width: 67px;
                height: 67px;
                border-radius: 10px;
                background: $light-blue-50;
                @include common-flex-jc;
                position: relative;
                input{
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    position: absolute;
                    cursor: pointer;
                }
                svg{
                    width: 23px;
                    height: 23px;
                    margin: 0 auto;
                    display: block;
                    path{
                        fill: $primary-color;
                    }
                }
                p{
                    font-size: 12px;
                    color: $link-color;
                    margin: 0;
                }
            }
        }
    }
}
.main-icon{
    width: 83px;
    height: 83px;
    border-radius: 50%;
    background-color: $primary-color;
    @include common-flex-jc;
    margin-bottom: 20px;
}

.content-wrapper{
    @include position-relative;
    margin-top: 4px;
    padding: 0 5px;
}
.sub-contet-wrapper{
    @include position-relative;
    margin-top: 44px;
    @media(max-width: 320px){
        margin-top: 20px;
    }
    h3{
        margin-bottom: 10px;
    }
    .alert{
        font-size: 13px;
        margin-top: 15px;
        padding: 10px;
        margin-bottom: 0;
        .btn-close{
            padding: 13px 13px 13px 6px;
        }
    }
    
}
.info-form{
    // margin-top: 28px;
}
.property{
    @include position-relative;
    @include common-flex-jc;
    align-items: flex-start;
    height: 200px;
    overflow-y: auto;
}
.property-card{
    @include position-relative;
    margin-bottom: 7px;
    .form-check-input{
        @include position-absolute;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 10;
        opacity: 0;

    }
    &--inner{
        @include position-relative;
        @include common-flex;
        align-items: flex-start;
        gap: 15px;
        padding: 12px 14px;
        border: solid 1px $border-color;
        border-radius: 8px;
        .virtual-checkbox{
            position: relative;
            @include common-flex-jc;
            width: 20px;
            height: 20px;
            flex-shrink: 0;
            flex-wrap: nowrap;
            border-radius: 50px;
            border: 1px solid rgba(19, 105, 233, 0.30);
            background: #F8FAFF;
            margin-top: 4px;
        }
        .property-data{
            @include position-relative-fl;
            width: calc(100% - 20px);
            .customer-name{
                font-size: 14px;
                font-weight: 700;
                margin-bottom: 2px;
            }
            address{
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.2px;
                margin: 0;
            }
        }
    }
}
.property-card .form-check-input:checked + .property-card--inner{
    background-color: $checkbox-bg;
}
.property-card .form-check-input:checked + .property-card--inner .virtual-checkbox{
    // background-color: $primary-color;
    border-color: $primary-color;
}
.property-card .form-check-input:checked + .property-card--inner .virtual-checkbox::after{
    @include position-absolute-fl;
    content: '';
    width: 8px;
    height: 8px;
    background-color: $primary-color;
    top: 5px;
    left: 5px;
    border-radius: 50%;
}

.availability-selection{
    .virtual-checkbox{
        border-radius: 5px;
        width: 18px;
        height: 18px;
    }
    .property-data {
        .customer-name{
            font-size: 16px;
            margin-bottom: 0;
        }
        p{
            font-size: 14px;
            margin: 0;
        }
    }
    .property-card .form-check-input:checked + .property-card--inner{
      background-color: $white-color;
  }
  .property-card .form-check-input:checked + .property-card--inner .virtual-checkbox{
      background-color: $primary-color;
      border-color: $primary-color;
  }
  .property-card .form-check-input:checked + .property-card--inner .virtual-checkbox::after{
      @include position-absolute-fl;
      content: '';
      width: 8px;
      height: 8px;
      background-color: $checkbox-bg;
      top: 5px;
      left: 5px;
      border-radius: 50%;
  }

}
.availability-selection .property-card .form-check-input:checked + .property-card--inner .virtual-checkbox{
    border-color: $primary-color;
    background-color: $white-color;
}
.availability-selection .property-card .form-check-input:checked + .property-card--inner .virtual-checkbox::after{
    border-radius: 2px;
    top: 4px;
    left: 4px;
    background-color: $primary-color;
}
.schedule-outer{
    @include position-relative;
    @include common-flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 15px;
    max-height: calc(100vh - 340px - 90px);
    overflow-y: auto;
    // @media(max-height:667px){
    //   max-height: calc(100vh - 328px);
    //   gap: 0;
    // }
    @media(max-width:767.98px){
        gap: 0;
        max-height: calc(100vh - 340px);
    }
    @media only screen 
    and (device-width : 375px) 
    and (device-height : 667px) 
    and (-webkit-device-pixel-ratio : 2) { 
        max-height: calc(100vh - 310px) !important;
        padding-bottom: 60px;
    }
    @media(max-width:565.98px){
        max-height: calc(100vh - 320px);
        padding-bottom: 40px;
    }
}

.schedule-added{
    @include position-relative;
    @include common-flex;
    background: $white-color;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 20px;
    align-items: flex-start;
    @media( max-width:565.98px){
        padding: 15px 7px;
    }
    &-left{
        width: 100%;
        @include common-flex;
        align-items: flex-start;
        @media( max-width:565.98px){
            width: 88%;
        }
    }
    .icon{
        @include common-flex-jc;
        width: 41px;
        height: 20px;
        background-color: transparent;
    }
    &-data{
        @include position-relative-fl;
        margin-left: 10px;
        text-align: left;
        h5{
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            margin: 0;
        }
        ul{
            margin: 5px 0 0 0;
            padding: 0;
            li{
                margin: 0 0 6px 0;
                padding: 0;
                font-weight: normal;
                font-size: 15px;
                line-height:1;
                position: relative;
                list-style: none;
                @media(max-width:320px){
                    font-size: 13px;
                }
            }
        }
        .constraints{
            font-size: 15px;
            line-height: 1.1;
            color: $danger;
            margin: 0;
            @include legacy-mobile{
                font-size: 12px;
            }
        }
    }
    .remove{
        color: #E14F4F;
    }
    .edit-schedule{
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: $primary-color !important;
        text-decoration: none;
        background: none;
        box-shadow: none;
        border: none;
        &:hover{
            opacity: .8;
            background: none;
            box-shadow: none;
            border: none;
        }
        &:focus, &:active{
            opacity: 1;
            background: none;
            background-color: none !important;
            box-shadow: none;
            border: none;
        }
    }
}
.add-availability{
    @include position-relative;
    border: 1px dashed rgba(19, 105, 233, 0.30);
    background: rgba(19, 105, 233, 0.05);
    border-radius: 10px;
    color: $link-color;
    font-size: 20px;
    text-align: center;
    padding: 19px 0;
    margin-bottom: 10px;
    cursor: pointer;
    &:hover{
        opacity: .8;
        color: $link-color;
    }
}


.global-card-sm{
    width: 100%;
    background: $white-color;
    border-radius: 8px;
    padding: 11px 11px 11px 24px;
    margin-bottom: 15px;
    &__customer-details{
        padding: 19px 24px 26px;

        &__item{
            &:not(:last-of-type){
                margin-bottom: 22px;
                // @media(max-height:667px){
                //     margin-bottom: 14px;
                // }
            }
        }
        &:last-child{
            // @media(max-height: 667px){
            //     margin-bottom: 0;
            // }
        }
    }
    &__properyt-address{
        @include common-flex-jsb;
        margin-bottom: 0px;
        &__map{
            width: 84px;
            height: 84px;
            border-radius: 10px;
            flex-shrink: 0;
            >*{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    label{
        font-size: 15px;
        color: $secondary-color;
        opacity: .5;
        margin-bottom: 5px;
    }
    p, address{
        font-size: 18px;
        color: $secondary-color;
        margin: 0;
        font-weight: 500;
        word-break: break-word;
    }
    address{
        @include sm-mobile{
            font-size: 16px;
        }
    }
    img{
        max-width: 100%;
    }
}

.content-wrapper-scrollable{
    @include position-relative;
    @include common-flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 15px;
    max-height: calc(100vh - 325px);
    overflow-y: auto;
    // @media(max-height: 667px){
    //   max-height: calc(100vh - 328px);
    //   overflow-x: hidden;
    // }
    @media(max-width:767.98px){
        padding-bottom: 30px;
    }
    @media only screen 
    and (device-width : 375px) 
    and (device-height : 667px) 
    and (-webkit-device-pixel-ratio : 2) { 
        max-height: calc(100vh - 320px) !important;
    }
    @media(max-width:565.98px){
        max-height: calc(100vh - 310px);
    }
    h4{
        font-size: 20px;
        font-weight: 500;
        color: $secondary-color;
        margin-bottom: 14px;
        @media(max-width: 767.98px){
            margin-bottom: 0;
        }
    }
}

.data-picker-outer{
    @include position-relative;
    @include common-flex-jsb;
    flex-direction: column;
    margin-bottom: 22px;
    .date-picker-input{
        @include position-relative;
        height: 46px;
        background: #F6F9FF url(../../assets/images/calender-icon.svg) no-repeat center right 2px;
        border: 1px solid #E3E7EF;
        border-radius: 8px;
        padding: 3px;
        font-size: 16px;
        text-indent: 10px;
    }

    .date-icon{
        @include position-absolute-fl;
        @include common-flex-jc;
        height: 38px;
        width: 38px;
        top: 4px;
        right: 4px;
        background-color: $primary-color;
        border-radius: 8px;
    }
    .react-datepicker{
        border: 1px solid #E3E7EF;
        font-size: .9rem;
        border-radius: 20px;
        overflow: hidden;
    }
    .react-datepicker__portal{
        background-color: rgba(0,0,0,.5);
    }
    .react-datepicker__header {
        background-color: $white-color;
        border-bottom: none;
    }
    .react-datepicker__day--selected{
        background-color: $primary-color !important;
        color: $white-color !important;
    }
    .react-datepicker__triangle{
        &:after{
            border-bottom-color: $white-color !important;
        }
        &:before{
            border-bottom-color: #E3E7EF !important;
        }
    }
    .react-datepicker__current-month{
        font-size: 22px;
        padding: 20px 0px;
        font-weight: 500;
    }
    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
        background-color: $primary-color;
    }
    .react-datepicker__day--today{
        border: solid 1px $primary-color;
        border-radius: 5px;
        color: $primary-color;
    }
    .react-datepicker__day--disabled.react-datepicker__day--today{
      border-color: #ccc;
      color: #ccc;
    }
    .react-datepicker__navigation--next {
        right: 13px;
        top: 23px;
    }
    .react-datepicker__navigation--previous{
        left: 13px;
        top: 23px;
    }
    .text-danger{
        text-align: left !important;
    }
}
.react-datepicker__input-container {
    display: block !important;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.datePicker {
  width: 100% !important;
}
.arrival-time-wrapper{
    @include position-relative;
    h4{
        line-height: 1;
    }
    p{
        font-size: 14px;
    }
    .arrival-time{
        margin-bottom: 8px;
        label{
            font-size: 14px;
            font-weight: 400;
            color: $text-color;
            display: flex;
            align-items: center;
            .form-check-input{
                position: relative;
                background-color: $checkbox-bg;
                border-color: rgba(19, 105, 233, 0.30);
                width: 18px;
                height: 18px;
                margin-right: 10px;
                &:checked{
                    background-color: $white-color;
                    border-color: $primary-color;
                    box-shadow: none;
                    &[type="checkbox"]{
                        background-image: none;
                    }
                    &::after{
                        @include position-absolute-fl;
                        content: '';
                        width:8px;
                        height: 8px;
                        background-color: $primary-color;
                        top: 4.2px;
                        left: 4.2px;
                        border-radius: 2px;
                    }
                }
            }
        }
    }
    .arrival-time-constraints{
        margin: 15px 0 0 0 !important;
        float: left;
        width: 100%;
        .time-constraints-info{
            margin: 0 0 15px 0;
        }
        .form-label{
            margin-bottom: 4px;
        }
    }
    .text-danger{
        text-align: left;
    }
}
.text-danger{
    width: 100%;
    float: right;
    text-align: right;
    font-size: 12px;
}
.custom-phone{
    background-color: transparent;
    border: none;
    font-size: 18px;
    color: $secondary-color;
    margin: 0;
    font-weight: 500;
    &:read-only{
      color: $secondary-color;
      opacity: 1 !important;
      padding: 0;
      &:focus{
        border: none;
        outline: none;
        box-shadow: none;
      }
    }
}
@include sm-mobile{
    .data-picker-outer .react-datepicker__portal{
        width: 100%;
    }
    .data-picker-outer .react-datepicker__portal > div{
        width: 100%;
    }
    .data-picker-outer .react-datepicker{
        width: 88%;
        left: 6%;
    }
    .react-datepicker__month-container{
        width: 100%;
        padding-bottom: 15px;
    }
    .react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name{
        width:12%;
    }
    .react-datepicker__month{
        margin: 0;
    }
}

.data-picker-outer > div{
    width: 100%;
}
.content-wrapper-opened{
  .schedule-outer{
    max-height: unset !important;
  }
}
.error-schedule {
    background: #fff5f5;
    border: solid 1px #ff6b6b;
    .icon{
        svg{
            path{
                fill: #ff6b6b;
            }
            line{
                stroke: #ff6b6b;
            }
        }
    }
    .schedule-added-data{
        h5{
            color: #ff6b6b;
        }
    }
}

.content-wrapper-opened{
    @media(max-width:767.98px){
        max-height: calc(100vh - 320px) !important;
    }
    
    @media only screen 
    and (device-width : 375px) 
    and (device-height : 667px) 
    and (-webkit-device-pixel-ratio : 2) {
        max-height: calc(100vh - 320px) !important;
        padding-bottom: 60px !important;

    }
    @media(max-width:565.98px){
        max-height: calc(100vh - 310px) !important;
        
    }
    .global-card-sm__customer-details__item{
        p{
            font-size: 14px;
            font-weight: 400;
           
        }
    }
    .global-card{
        font-size: 15px;
        p{
            margin: 0;
            padding: 0;
        }
    }
    .global-custom-padding{
        @media(max-width:565.98px){
            margin-bottom: 60px !important;
        }
    }
}
.resident-info-data{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    height: calc(100vh - 360px);
}
.intro-base{
    color: #101828 !important;
    line-height: 20px; 
}
.intro-btn-base{
    text-align: left;
    display: flex;
    padding: 15px;
    gap: 10px;
    div{
        font-size: 15.814px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.326px; 
    }
}