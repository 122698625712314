@mixin h($size) {
  margin: 0;
  line-height: 1.3;
  font-weight: 600;
  @if $size==1 {
    @include font-smoothing(on);
    font-size: 22px;
    line-height: 24px;
   
  }

  @if $size==2 {
    @include font-smoothing(on);
    font-size: 24px;
    line-height: 26px;
    color: $secondary-color;
    margin-bottom: 6px;
    @media( max-width:320px){
      font-size: 20px;
    }
  }

  @if $size==3 {
    font-size: 19px;
    line-height: 22px;
    margin-bottom: 6px;
    font-weight: 500;
  }
  @if $size==4 {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color: $secondary-color;
  }
  @if $size==5 {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 17px;
  }
 
  
}

@mixin font-smoothing($value: on) {
  @if $value==on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

@mixin position-absolute {
  position: absolute;
  width: 100%;
}

@mixin position-absolute-fl {
  position: absolute;
  float: left;
}

@mixin position-relative {
  position: relative;
  width: 100%;
  float: left;
}

@mixin position-relative-fl {
  position: relative;
  float: left;
}

@mixin position-fixed {
  position: fixed;
  width: 100%;
  float: left;
}

@mixin position-fixed-fl {
  position: fixed;
  float: left;
}

@mixin position-relative-fr {
  position: relative;
  float: right;
}

@mixin common-flex {
  display: flex;
  align-items: center;
}

@mixin common-flex-jc {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin common-flex-jc-as {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

@mixin common-flex-jsb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@mixin desktop {
  @media (max-width: 1199.98px) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: 991.98px) {
    @content;
  }
}
@mixin mobile {
  @media (max-width: 767.98px) {
    @content;
  }
}
@mixin sm-mobile {
  @media (max-width: 565.98px) {
    @content;
  }
}
@mixin legacy-mobile {
  @media (max-width: 349.98px) {
    @content;
  }
}

// @mixin common-scroll {
//   &::-webkit-scrollbar {
//     width: 6px;
//   }

//   /* Track */
//   &::-webkit-scrollbar-track {
//     background: $color-white;
//     border-radius: 5px;
//   }

//   /* Handle */
//   &::-webkit-scrollbar-thumb {
//     background: $nav-color;
//     border-radius: 6px;
//   }

//   /* Handle on hover */
//   &::-webkit-scrollbar-thumb:hover {
//     background: $nav-color;
//   }
// }
