$bg-color : #F6F7FB;;
$primary-color: #1369E9;
$primary-light: #F3F9ED;
$secondary-color: #283A5D;
$link-color: #1369E9;
$link-color2: #1D66FF;
$light-gray-10: rgba(40, 58, 93, 0.2);
$light-gray-15: rgba(15, 94, 103, 0.05);
$light-gray-20: rgba(231, 234, 241, 0.5);
$light-gray-30:  rgba(137, 147, 169, 0.6);
$light-gray-40: #d5d5d5;
$light-gray-50: #A9B0BE;
$light-gray-60: #E1E5EB;
$confirm-box-bg: #EBF0FB;
$text-color: #69768E;
$danger: #FF4949;
$white-color: #FFFFFF;
$border-color: #E3E7EF;
$checkbox-bg: #F6F9FF;
$global-font: "Roboto", sans-serif;
$primary-color-10: rgba(130, 194, 66, 0.1);
$light-gray-70:  rgba(40, 58, 93, 0.7);
$border-gray: #BFBFBF;
$border-gray-2: #E4E4E4;
$black-color: #000;
$shadow-1:  0px 0px 20px rgba(40, 58, 93, 0.1);
$light-blue-50: #E7F0FD;