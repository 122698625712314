.modal{
    &-dialog{
        padding: 15px;
    }
    &-content{
        border-radius: 20px;
    }
    &-header{
        border-bottom: none;
        padding: 20px;
    }
    &-body{
        padding-top: 0px;
        h2{
            font-size: 22px;
        }
        ul{
            margin: 20px 0 30px 0;
            padding: 0;
            li{
                font-size: 15px;
                margin-bottom: 10px;
                list-style: none;
                padding-left: 25px;
                position: relative;
                &::after{
                    @include position-absolute;
                    content:'';
                    width: 10px;
                    height: 10px;
                    top: 5px;
                    left: 4px;
                    background-color: $danger;
                }
            }
        }
        .modal-action{
            @include position-relative;
            @include common-flex-jc;
            flex-direction: column;
            gap: 15px;
            .btn{
                padding-top: 7px;
                padding-bottom: 7px;
            }
        }
        .modal-action--row{
          @include position-relative;
          @include common-flex-jc;
          gap: 15px;
          .btn{
              padding-top: 7px;
              padding-bottom: 7px;
          }
      }
    }
}
.submitted-wrapper{
    width: 100%;
    padding: 15px 15px 80px;
    text-align: center;
    h4{
        font-size: 24px;
        font-weight: 500;
        color: $secondary-color;
        margin: 19px 0 17px;
        line-height: 1.2;
    }
    h5{
        font-size: 20px;
        font-weight: 500;
        color: $secondary-color;
        margin: 0;
        line-height: 1.7;
    }
    p{
        font-size: 18px;
        margin: 0;
        color: $light-gray-70;
        line-height: 1.5;
    }
}
.property-access-details-form{
    padding: 10px 0 36px;
    .form-check{
        margin-bottom: 15px;
        position: relative;
        padding: 0;
        &-input{
            opacity: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            margin: 0;
            z-index: 1;
        }
        label{
            display: flex;
            align-items: baseline;
            width: 100%;
            p{
                font-size: 16px;
                color: $black-color;
                opacity: .6;
                margin: 0;
            }
            .circle{
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 1px solid $border-gray;
                margin-right: 10px;
                flex-shrink: 0;
                @include common-flex-jc;
                &-selected{
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: $primary-color;
                    visibility: hidden;
                    opacity: 0;
                }
            }
        }
        input:checked + label p{
            opacity: 1;
            font-weight: 500;
        }
        input:checked + label .circle {
            border-color: $primary-color;
            .circle-selected{
                visibility: visible;
                opacity: 1;
            }
        }
    }
    .specific-access-note{
        width: 100%;
        height: 45px !important;
        border-radius: 4px;
        border: 1px solid $border-gray-2;
        padding: 9px 12px;
        color: $black-color;
        font-size: 16px;
        &:focus{
            box-shadow: none;
            border-color: $primary-color;
        }
    }
    ::placeholder{
        opacity: .6;
    }
}

.verify-otp-modal{
    .otp-form-wrapper{
        @include position-relative;
        @include common-flex-jc;
        margin-bottom: 25px;
        flex-direction: column;
        @media(max-width:320px){
            margin-bottom: 4px;
        }
        .form-control{
            width: 126px;
            text-align: center;
        }
        p{
            text-align: center;
            margin-top: 25px;
            .btn-link{
                color: $link-color2;
                background-color: transparent;
                box-shadow: none;
                border: none;
                padding: 0;
                text-decoration: none;
                &:hover{
                    opacity: .8;
                    text-decoration: underline;
                }
            }
            span{
                color: $primary-color;
            }
        }
        .text-danger{
            text-align: center !important;
        }
    }
}

.time-constraints-info{
    @include position-relative;
    background: #F7F9FF;
    border: 1px solid #E3E7EF;
    border-radius: 8px;
    padding: 11px 17px;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: rgba(40, 58, 93, 0.7);
    margin: 15px 0;
    &:focus::placeholder {
      color: transparent;
    }
}
.troubleshoot-details{
    @include position-relative;
    background: #F7F9FF;
    border: 1px solid #E3E7EF;
    border-radius: 8px;
    padding: 11px 17px;
    font-weight: 400;
    font-size: 15px;
    text-align: center;
    line-height: 20px;
    color: rgba(40, 58, 93, 0.7);
    margin: 15px 0;
    // @media( max-width:667px){
    //     margin-bottom: 30px;
    //     padding: 11px 14px;
    // }
    p{
        margin: 0;
        @media(max-width:320px){
            font-size: 14px;
        }
    }
    a{
        color: $link-color;
        text-decoration: none;
        @media(max-width:320px){
            font-size: 14px;
        }
        &:hover{
            opacity: .8;
            text-decoration: underline;
        }
    }
}
.modal-data-card{
    @include position-relative;
    padding: 15px;
    background: $checkbox-bg;
    border: 1px solid $border-color;
    border-radius: 10px;
    margin: 10px 0 20px 0;
    max-height: 440px;
    overflow-y: auto;
    @media(max-width: 565.98px){
      max-height: 340px;
    }
    .data-card--item{
        @include position-relative;
        margin-bottom: 5px;
        h4{
            font-weight: 500;
            font-size: 18px;
            color: $secondary-color;
        }
        p{
            color: #69768e;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
        }
    }
}
