.troubleshoot{
    @include position-relative;
    @include common-flex;
    align-items: flex-start;
    flex-direction: column;
    max-height: calc(100vh - 340px);
    overflow-y: auto;
    padding-bottom: 20px;
    @media(max-width:767.98px){
        padding-bottom: 30px;
    }
    @media only screen 
    and (device-width : 375px) 
    and (device-height : 667px) 
    and (-webkit-device-pixel-ratio : 2) { 
        max-height: calc(100vh - 330px) !important;
        padding-bottom: 60px !important;
    }
    @media(max-width:565.98px){
        max-height: calc(100vh - 310px);
    }
}
.service-outer{
    @include position-relative;
    @include common-flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 15px;

    .service-card{
        @include position-relative-fl;
        @include common-flex-jc;
        flex-direction: column;
        background-color: $white-color;
        border-radius: 10px;
        width: calc(100% / 2 - 7.5px);
        height: 113px;
        flex-shrink: 0;
        gap:10px;
        color: $text-color;
        text-decoration: none;
        @media(max-width: 565.98px){
          height: 100px;
        }
        @include legacy-mobile{
            font-size: 14px;
        }
        &:hover{
            background-color: $primary-color;
            color: $white-color;
            svg{
                path{
                    fill: $white-color;
                }
                rect{
                    stroke: $white-color;
                }
                line{
                    stroke: $white-color;
                }
                circle{
                    stroke: $white-color;
                }
            }
        }
    }
}
