.form-control{
    background: #FFFFFF;
    border: 1px solid rgba(40, 58, 93, 0.2);
    border-radius: 6px;
    height: 48px;
}
.info-form{
    .input-wrapper{
        @include position-relative;
        background: $white-color;
        border: 1px solid rgba(40, 58, 93, 0.2);
        border-radius: 6px;
        height: 48px;
        margin-top: 12px;
        .form-control{
            width:90%;
            border: none;
            background-color: transparent;
            font-size: 20px;
            color: $text-color;
            &:focus{
                box-shadow: none;
            }
        }
        .close-icon{
            @include position-absolute-fl;
            top: 11px;
            right: 9px;
            background-color: transparent;
            border-style: none;
            padding: 0;
        }
        
    }
}
.support-line{
    font-size: 16px;
    line-height: 1.7 !important;
    margin: 0;
}