.steps{
    @include position-relative-fl;
    @include common-flex-jsb;
    width:100%;
    list-style: none;
    padding: 0;
    li{
        @include position-relative-fl;
        @include common-flex-jc;
        width:31px;
        height: 31px;
        flex-grow: 0;
        .dot{
           @include position-relative-fl;
           @include common-flex-jc;
           width: 22px;
           height: 22px;
           background-color: $bg-color;
           border-radius: 50%;

            &--inner{
                width: 10px;
                height: 10px;
                background-color: $light-gray-60;
                border-radius: 50%;
            }

        }
        &.step-divider{
            height: 2px;
            background-color:  $light-gray-60;
            flex-grow: 1 !important;
        }
        &.selected{
            .dot{
                background-color: $primary-color;
                .dot--inner{
                    background-color: $white-color;
                }
            }

        }
        &.completed{
            .dot{
                background-color: transparent;
                .dot--inner{
                    background-color: $primary-color;
                }
            }

        }
        &.completed + .step-divider{
            background-color: $primary-color;
        }
    }
}
