.footer-action{
    background-color: $white-color;
    box-shadow: $shadow-1;
    min-height: 88px;
    @include position-fixed;
    @include common-flex;
    gap: 16px; 
    left: 0; 
    bottom: 0;
    padding: 24px;
    transform: translate3d(0,0,0);
    z-index: 10;
}