.btn{
    font-size: 16px;
    font-weight: 400;
    padding-top: 9.5px;
    padding-bottom: 9.5px;
    min-height: 45px;
    @include common-flex-jc;
}
.btn-primary{
    background-color: $primary-color;
    border-color: $primary-color;

    &:hover{
        background-color: $primary-color;
        opacity: .8;
        border-color: $primary-color;
    }
    &:focus{
        background-color: $primary-color;
        opacity: .8;
        border-color: $primary-color;
        box-shadow: none;
        color: $white-color;
    }
    &:active:focus{
        background-color: $primary-color;
        opacity: .8;
        border-color: $primary-color;
        box-shadow: none;
        color: $white-color;
    }
    &.disabled{
        background-color: rgba(137, 147, 169, 0.6);
        border-color: rgba(137, 147, 169, 0.6);
    }
}

.btn-secondary{
    background-color: $light-gray-20;
    border-color: $light-gray-20;
    color: $secondary-color;

    &:hover{
        background-color: $light-gray-20;
        opacity: .8;
        color: $secondary-color;
        border-color: $light-gray-20;
    }
    &:focus{
        background-color: $light-gray-20;
        opacity: .8;
        border-color: $light-gray-20;
        box-shadow: none;
        color: $secondary-color;
    }
    &:active:focus{
        background-color: $light-gray-20;
        opacity: .8;
        border-color: $light-gray-20;
        box-shadow: none;
        color: $secondary-color;
    }
    &.disabled{
        background-color: rgba(137, 147, 169, 0.6);
        border-color: rgba(137, 147, 169, 0.6);
    }
}


.btn-danger{
  background-color: $danger;
  border-color: $danger;
  color: $white-color;

  &:hover{
      background-color: $danger;
      opacity: .8;
      border-color: $danger;
  }
  &:focus{
      background-color: $danger;
      opacity: .8;
      border-color: $danger;
      box-shadow: none;
      color: $white-color;
  }
  &:active:focus{
      background-color: $danger;
      opacity: .8;
      border-color: $danger;
      box-shadow: none;
      color: $white-color;
  }
  &.disabled{
      background-color: rgba(137, 147, 169, 0.6);
      border-color: rgba(137, 147, 169, 0.6);
  }
}
