@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

body {
  font-family: $global-font !important;
  font-size: 17px !important;
  font-weight: 400 !important;
  line-height: 20px;
  color: $secondary-color;
  float: left;
  width: 100%;
  background: $bg-color;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
}

h1,
.h1 {
  @include h(1);
}

h2,
.h2 {
  @include h(2);
}

h3,
.h3 {
  @include h(3);
  small{
    font-size: 14px;
    font-weight: 400;
    @include sm-mobile{
      font-size: 12px;
    }
  }
}

h4,
.h4 {
  @include h(4);
  small{
    font-size: 14px;
    @include sm-mobile{
      font-size: 12px;
    }
  }
}

h5,
.h5 {
  @include h(5);
}

h6,
.h6 {
  @include h(6);
}
p{
  color: $text-color;
  line-height: 22px;
  @include sm-mobile{
    font-size: 15px;
    line-height: 1.1;
  }
  a{
    color: $link-color;
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }
}
